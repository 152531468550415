@use 'styles/theme';

.container {
  display: grid;
  grid-template-rows: auto 1fr;
  height: 100%;
}

.header {
  padding: 6rem 3.556rem 4.5rem;

  @include theme.for-large-desktop-up {
    padding-right: 15%;
  }
}

.content {
  background-color: theme.$color-lightest-gray;
  border-top-left-radius: 1rem;
  border-top-right-radius: 1rem;
  display: grid;
  gap: 2rem;
  padding: 4rem 3.556rem 4.5rem;

  @include theme.for-large-desktop-up {
    padding-right: 15%;
  }
}
