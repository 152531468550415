@use 'styles/theme';

.header {
  font-size: 14px;
  padding: 6rem 4.75rem 3rem;

  a {
    &:active,
    &:link,
    &:visited {
      color: theme.$color-blue;
      margin-left: 0.25rem;
    }
  }
}

.content {
  background: none;
  overflow-y: hidden;
  padding: 0 25% 2rem 4.75rem;
  position: relative;
  z-index: 0;

  &::before {
    background-color: theme.$color-lightest-gray;
    content: '';
    display: block;
    height: 100%;
    left: 0;
    position: absolute;
    top: 13.35rem;
    width: 100%;
    z-index: -1;
  }

  h2 {
    font-size: 21px;
    line-height: 1.19;
    margin-bottom: 1.25rem;
  }
}

.mergeRecords {
  display: grid;
  gap: 2rem;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1.3fr;

  &Comments {
    margin-top: 25px;
  }
}
.record {
  background-color: theme.$color-white;
  border-radius: 10px;
  box-shadow: 0 1px 6px 0 rgba(60, 64, 67, 0.3);
  display: grid;
  grid-template-rows: auto 1fr auto;
  padding: 0.75rem 1rem;

  h3 {
    font-family: theme.$font-family-secondary;
    font-size: 16px;
    margin: 0;

    span {
      font-family: theme.$font-family-primary;
      font-weight: normal;
      margin-left: 0.5rem;
    }
  }
  &Section {
    column-gap: 1rem;
    display: grid;
    font-size: 15px;
    grid-auto-rows: min-content;
    grid-template-columns: 1fr 1.75fr;
    line-height: 1.2;
    margin-top: 1.5rem;
    padding: 0.5rem 0;
    row-gap: 0.889rem;

    + .recordSection {
      border-top: 1px solid theme.$color-border-gray;
      margin-top: 0.65rem;
      padding-top: 1.25rem;
    }
  }
  &Title {
    font-size: 11px;
    font-weight: 500;
    letter-spacing: 0.75px;
    text-transform: uppercase;
  }
  &Detail {
    ul {
      list-style: none;
      margin: 0;
      padding: 0;
    }
    li {
      margin-top: 0.25rem;

      &:first-child {
        margin-top: 0;
      }
    }
  }

  .error {
    color: theme.$color-comptia-red;
    font-weight: bold;
    text-align: center;
  }

  .button {
    align-self: end;
    display: grid;
    margin: 0.75rem auto;
    width: calc(100% - 30%);
  }
}

.mergeActions {
  column-gap: 1rem;
  display: grid;
  grid-template-columns: 0.25fr 0.25fr;
  margin-top: 2.5rem;
}
