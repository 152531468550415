@use 'styles/theme';

.preview,
.grid {
  display: grid;
  gap: 1rem;
  grid-template-columns: 1fr 2fr min-content;

  .column {
    display: grid;
    gap: 1rem;
  }
}

.grid {
  align-items: flex-start;
}

.preview,
.content {
  background-color: theme.$color-lightest-gray;
}

.card {
  background-color: theme.$color-lightest-gray;
  border: 1px solid;
  font-size: 0.8rem;
  margin: 2em;
}

.public {
  background-color: theme.$color-pale-blue;
}

.comment {
  background-color: theme.$color-lightest-gray;
}

.detail {
  font-family: 'Gotham', sans-serif;
  font-size: 0.65rem;
  line-height: 0.775rem;
  margin-bottom: 0.15rem;
}

p {
  font-size: unset;
}
