@use 'styles/theme';

.initials {
  align-items: center;
  background-color: theme.$color-pink;
  border-radius: 50%;
  color: theme.$color-white;
  display: flex;
  font-family: theme.$font-family-secondary;
  font-size: 12px;
  font-weight: bold;
  height: 32px;
  justify-content: center;
  line-height: 1em;
  width: 32px;
}
