@use 'styles/theme';

$input-height: 72px;
$input-padding: 32px;
$input-font-size: 23px;

.container {
  svg {
    color: #687077;
    font-size: 35px;
  }

  &.focused,
  &.filled {
    svg {
      color: theme.$color-comptia-red;
    }
  }
}

.label {
  color: #687077;
  font-size: $input-font-size;
  left: $input-padding;
  padding-left: 0;
  padding-right: 0;

  .focused &,
  .filled & {
    border: 0;
    clip: rect(0 0 0 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
  }
}

.input {
  border-color: #d8dfe1;
  border-radius: $input-height * 0.5;
  border-width: 2px;
  color: #687077;
  font-size: $input-font-size;
  height: $input-height;
  outline: none;
  padding-left: $input-padding;
  padding-right: $input-padding;
  transition: border-color 0.3s ease;

  .focused &,
  .filled & {
    border-color: theme.$color-comptia-red;
  }
}
