@use 'styles/theme';

.columns,
.column {
  display: grid;
}

.columns {
  gap: 2rem;
  grid-template-columns: 1fr 1fr;
}

.column {
  gap: 1rem;
  grid-auto-rows: min-content;
}

.buttons {
  > button + button {
    margin-left: 0.5rem;
  }
}

.detail {
  align-items: center;
  display: grid;
  grid-template-columns: 1fr auto;

  .label {
    font-family: theme.$font-family-secondary;
    font-size: 0.75rem;
    font-weight: normal;
    line-height: 0.875rem;
  }

  .value {
    font-size: 0.889rem;
    line-height: 1.438rem;
  }
}

.header {
  font-family: theme.$font-family-secondary;
  font-size: 1rem;
}

.descriptionterm,
.descriptiondetail {
  display: inline-block;
  font-family: theme.$font-family-secondary;
  font-size: 0.75rem;
  line-height: 0.875rem;
  padding-bottom: 0.5rem;
  width: 50%;
}

.descriptionterm {
  font-weight: bold;
  width: 30%;
}

.descriptiondetail {
  margin: 0;
  width: 70%;
}

.checkbox {
  justify-content: right;
}
