@use './variables';

@mixin for-mobile-landscape {
  @media only screen and (min-device-width: variables.$breakpoint-mobile-min) and (max-device-width: variables.$breakpoint-mobile-max) and (orientation: landscape) {
    @content;
  }
}
@mixin for-iphone678-landscape {
  @media only screen and (min-device-width: variables.$breakpoint-iphone678-min) and (max-device-width: variables.$breakpoint-iphone678-max) and (orientation: landscape) {
    @content;
  }
}
@mixin for-tablet-up {
  @media screen and (min-width: variables.$breakpoint-tablet-min) {
    @content;
  }
}
@mixin for-tablet-landscape {
  @media only screen and (min-device-width: variables.$breakpoint-tablet-min) and (max-device-width: variables.$breakpoint-desktop-min) and (orientation: landscape) {
    @content;
  }
}

@mixin for-desktop-up {
  @media screen and (min-width: variables.$breakpoint-desktop-min) {
    @content;
  }
}

@mixin for-large-desktop-up {
  @media screen and (min-width: variables.$breakpoint-large-desktop-min) {
    @content;
  }
}
