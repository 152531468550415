.card {
  padding: 0;
}

.columns,
.column {
  display: grid;
  gap: 2rem;
}

.columns {
  grid-template-columns: 1fr 1fr;
}

.column {
  grid-auto-rows: min-content;
}

.buttons {
  > button + button {
    margin-left: 0.5rem;
  }
}

.phone {
  display: grid;
  gap: 1rem;
  grid-template-columns: 4fr 8fr;
}

.container {
  padding: 2rem;
}

.viewbuttoncontainer {
  display: flex;
  padding: 0 2rem 1.5rem;
}

.viewbutton {
  align-items: center;
  background: none;
  border: none;
  cursor: pointer;
  display: inline-flex;
  font-family: inherit;
  font-size: 0.75rem;
  font-weight: bold;
  line-height: 0.875rem;
  margin-left: auto;
  padding: 0;
  position: relative;

  svg {
    margin-left: 0.5rem;
  }
}
