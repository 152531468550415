.columns,
.column {
  display: grid;
  gap: 2rem;
}

.buttons {
  > button + button {
    margin-left: 0.5rem;
  }
}

.container {
  padding: 2rem;
}
