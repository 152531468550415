@use 'styles/theme';

.content,
.social {
  padding: 2rem;
}

.social {
  font-size: 0.8rem;
  font-weight: bold;
}

.input {
  margin-top: 1rem;
}

.button {
  margin-top: 1rem;
  width: 50%;
}
