@use 'styles/theme';

.container {
  align-items: center;
  display: flex;
  font-size: 0.8rem;
  justify-content: flex-end;
  list-style: none;
  margin: 0.5rem 0 0;
  padding: 0;

  li {
    align-items: center;
    display: inline-flex;
    padding: 0;

    + li {
      margin-left: 1rem;
    }
  }

  a {
    color: theme.$color-medium-gray;
    cursor: pointer;
    display: inline-flex;
  }

  .active {
    font-weight: bold;

    a {
      color: theme.$color-comptia-red;
    }
  }

  .previous,
  .next {
    a {
      color: theme.$color-black;
    }
  }
}
