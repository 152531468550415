@use 'styles/theme';
.content {
  display: grid;
  gap: 2rem;
}

.grid {
  display: grid;
  gap: 1rem;
  grid-template-columns: 1fr 1fr;
  padding: 2rem;

  .key,
  .title {
    grid-column: span 2;
  }
}

.checkbox-detail {
  .label {
    font-family: theme.$font-family-secondary;
    font-size: 0.75rem;
    line-height: 0.875rem;
  }

  .value {
    font-size: 0.889rem;
    line-height: 1.438rem;
  }
}
