@use 'styles/theme';

.card {
  background-color: theme.$color-white;
  border-radius: 4px;
  box-shadow: 0.1rem 0.1rem 0.25rem 0 rgba(0, 0, 0, 0.1);
  overflow: hidden;
  padding: 1.25rem 2rem;

  &:hover {
    box-shadow: 2px 2px 4px 0 rgba(0, 0, 0, 0.25);
  }

  &.collapsed {
    padding: 0;
  }
}
