$breakpoint-large-desktop-min: 1440px;
$breakpoint-desktop-min: 1024px;
$breakpoint-tablet-min: 768px;
$breakpoint-iphone678-max: 736px;
$breakpoint-iphone678-min: 375px;
$breakpoint-mobile-max: 480px;
$breakpoint-mobile-min: 320px;

// Colors.
$color-black: #000;
$color-blue: #004f71;
$color-light-blue: #0085bc;
$color-comptia-black: #253746;
$color-comptia-red: #c8102e;
$color-dark-gray: #3c4043;
$color-darkest-gray: #202124;
$color-icon-gray: #5f6368;
$color-lightest-gray: #eff2f2;
$color-light-gray: #dadce0;
$color-lime: #64a608;
$color-border-gray: #bdc1c6;
$color-disabled-gray: #c0c5c8;
$color-medium-gray: #788186;
$color-rich-gray: #4e5255;
$color-light-mint: #e2f3e8;
$color-off-white: #eff2f2;
$color-green: #18ab56;
$color-orange: #f26d20;
$color-pale-blue: #dff0f6;
$color-palest-blue: #f2fcff;
$color-pink: #9b60ab;
$color-purple: #582c83;
$color-light-teal: #e2f3e8;
$color-teal: #0e7377;
$color-yellow: #d87e08;
$color-white: #fff;

// Fonts.
$font-family-gotham: 'Gotham', sans-serif;
$font-family-roboto: 'Roboto', sans-serif;

// Inputs.
$checkbox-height: 21px;
$input-height: 52px;
$input-height-small: 36px;

// Theme.
$color-default: $color-comptia-black;
$color-primary: $color-blue;
$color-accent: $color-light-blue;
$font-family-primary: $font-family-roboto;
$font-family-secondary: $font-family-gotham;
