#root {
  height: 100%;
}

h1,
h2,
h3 {
  margin: 0;
}

h1 {
  font-size: 1.556rem;
  line-height: 1em;
  margin-bottom: 0.889rem;
}

h2 {
  font-size: 1.167rem;
  line-height: 1em;
  margin-bottom: 0.444rem;
}

h3 {
  font-size: 1rem;
  line-height: 1em;
  margin-bottom: 0.444rem;
}

p {
  margin-bottom: 1.333rem;
}
