@use 'styles/theme';

.container {
  margin-bottom: 1rem;
  position: relative;
  text-align: left !important;

  a {
    color: inherit;
    display: block;
    text-decoration: none;
  }

  &.disabled {
    opacity: 0.65;
  }

  .checkbox {
    left: 1.5rem;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);

    button {
      border-color: #666;
      border-radius: 0;
      border-width: 2px;
      color: theme.$color-comptia-red;
    }
  }

  .close {
    background: none;
    border: none;
    cursor: pointer;
    left: 1.5rem;
    padding: 0;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
  }

  .icon {
    font-size: 1em;
    pointer-events: none;
    position: absolute;
    right: 1rem;
    top: 50%;
    transform: translateY(-50%);
  }

  .grid {
    display: grid;
    gap: 0.5rem;
    grid-template-columns: repeat(4, minmax(0, 25%));
    padding: 1.5rem 4rem;
    width: 100%;

    .value {
      overflow-x: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    .archived {
      font-size: 0.8rem;
      font-style: italic;
      margin-left: 0.5rem;
      opacity: 0.7;
    }
  }
}
