.cards {
  display: grid;
  gap: 1rem;
}

.no-entitlements {
  text-align: center;
}

.spinner {
  display: block;
  height: 2rem;
  margin-left: auto;
  margin-right: auto;
  width: 2rem;
}
