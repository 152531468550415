@use 'styles/theme';

.container {
  align-items: center;
  background-color: #fff;
  border-radius: 4px;
  box-shadow: 2px 2px 4px 0 rgba(0, 0, 0, 0.09);
  color: theme.$color-comptia-black;
  display: grid;
  font-size: 0.889rem;
  gap: 1rem;
  grid-template-columns: 1.5fr 1fr auto;
  margin: 15px 0;
  padding: 1.25rem 0.85rem;
  text-decoration: none;
  width: 100%;

  &:hover {
    box-shadow: 2px 2px 4px 0 rgba(0, 0, 0, 0.25);
  }

  .column {
    display: grid;
    gap: 1rem;
    grid-template-rows: 1fr 1fr;
  }

  .item {
    display: flex;
    flex: 1;
    flex-direction: column;
    min-width: 0;

    strong,
    span {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
    strong {
      font-family: theme.$font-family-secondary;
      font-size: 0.667rem;
      margin-bottom: 0.25rem;
    }
  }

  .link {
    padding-top: 13.5px;

    svg {
      font-size: 18px;
    }
  }
}
