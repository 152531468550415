@use 'styles/theme';

.searchUserProfiles {
  &Fields {
    .searchTop,
    .searchBottom {
      column-gap: 2rem;
      display: grid;
      grid-template-columns: 2fr 1fr;
      margin: 1rem 0;

      .gridFields {
        column-gap: 1.5rem;
        display: grid;
      }
    }

    .searchTop {
      .gridFields {
        grid-template-columns: 1fr 1fr;
      }
    }

    .searchBottom {
      .gridFields {
        grid-template-columns: 2.5fr 2fr 2fr 2.5fr;
      }

      .buttons {
        display: grid;
        gap: 1rem;
        grid-template-columns: 1fr 1fr;

        .button {
          // max-width: 190px;
          width: 100%;

          &Secondary {
            border-color: theme.$color-comptia-black;
            color: theme.$color-comptia-black;
          }
        }
      }
    }
  }
}
