@use 'styles/theme';

.preview,
.grid {
  display: grid;
  gap: 1rem;
  grid-template-columns: 1.5fr 1fr 2fr min-content;

  .column {
    display: grid;
    gap: 1rem;
  }
}

.preview {
  cursor: pointer;
}

.grid {
  align-items: flex-start;
}

.preview,
.content {
  padding: 1.25rem 2rem;
}

.content {
  background-color: theme.$color-pale-blue;
}

.buttons {
  display: grid;
  gap: 1rem;
  grid-template-columns: 1fr 1fr;
  margin-left: auto;
  margin-right: 0;
  width: 400px;
}

.toggle,
.edit {
  background: none;
  border: none;
  cursor: pointer;
}
