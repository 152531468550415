@use 'styles/theme';

/* Search Result */
.userMergeQueue,
.userSearchResults {
  h6 {
    font-family: theme.$font-family-gotham;
    font-size: 21px;
    font-weight: 700;
    margin: 0 0 0.5rem 0;
  }
}
.userMergeQueue {
  border-bottom: 2px solid #979797;
  margin-bottom: 1.25rem;
  padding-bottom: 1.25rem;
}
.searchResults {
  .button {
    float: right;
    margin-top: 0.5rem;
    max-width: 200px;
  }
}
