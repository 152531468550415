@use 'styles/theme';

.redemptions {
  font-size: 0.8rem;

  .card {
    padding: 1.25rem;
  }

  .table {
    border-collapse: collapse;
    width: 100%;

    td {
      padding: 0.5rem 0;
    }

    tr + tr {
      td {
        border-top: 1px solid theme.$color-border-gray;
      }
    }
  }
}
