@use 'styles/theme';

$sidebar-width: 322px;

.container {
  background-color: #fff;
  color: theme.$color-comptia-black;
  display: grid;
  grid-template-rows: auto 1fr;
  height: 100%;
}

.columns {
  display: flex;
}

.sidebar {
  background-color: theme.$color-comptia-red;
  flex: $sidebar-width;
  padding-top: 5rem;
}

.main {
  width: calc(100% - #{$sidebar-width});
}
