@use 'styles/theme';

.content {
  display: grid;
  gap: 2rem;
}

.activate,
.deactivate {
  max-width: 400px;
}
