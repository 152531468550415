@use 'styles/theme';

.content {
  padding: 2rem;
}

.value {
  display: block;
  margin-top: 1rem;
}

.viewAll {
  cursor: pointer;
  display: block;
  font-family: theme.$font-family-secondary;
  font-size: 0.778rem;
  font-weight: bold;
  margin-top: 2rem;
}
