@use 'styles/theme';

.title {
  display: flex;
  justify-content: space-between;
  margin-bottom: 0.5rem;

  .greeting {
    $greeting-height: 64px;

    align-items: center;
    background-color: theme.$color-lightest-gray;
    border-bottom-left-radius: $greeting-height * 0.5;
    border-bottom-right-radius: 1rem;
    border-top-left-radius: $greeting-height * 0.5;
    border-top-right-radius: 1rem;
    display: inline-flex;
    font-family: theme.$font-family-secondary;
    height: $greeting-height;
    line-height: 1em;

    .initials {
      flex-shrink: 0;
      font-size: $greeting-height * 0.4;
      height: $greeting-height;
      width: $greeting-height;
    }

    .display {
      display: flex;
      flex-direction: column;
      padding-left: 1rem;
      padding-right: 2rem;

      .link {
        align-items: center;
        color: inherit;
        display: flex;
        font-size: 0.875rem;
        font-weight: bold;
        text-decoration: none;
        white-space: nowrap;
      }
    }
  }
}

.breadcrumb {
  margin-bottom: 2rem;

  .subheading {
    align-items: center;
    display: flex;
    margin-bottom: 1rem;

    .icon {
      color: theme.$color-comptia-red;
      margin-right: 0.5rem;
    }
  }

  .back {
    padding-left: 40px;
    padding-right: 40px;
    width: auto;
  }
}

.preview-container {
  .preview {
    background-color: theme.$color-pale-blue;
    display: grid;
    gap: 1rem;
    grid-template-columns: repeat(5, auto);
    padding: 1.25rem 2rem;
    width: 100%;

    .item {
      display: flex;
      flex: 1;
      flex-direction: column;
      min-width: 0;

      .label,
      .value {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }

      .label {
        font-family: theme.$font-family-secondary;
        font-size: 0.75rem;
        line-height: 0.875rem;
        margin-bottom: 0.25rem;
      }

      .value {
        font-size: 0.889rem;
        line-height: 1.438rem;
      }
    }
  }
}

.postToPearsonRow {
  display: flex;
  justify-content: flex-end;
  margin-bottom: 1rem;

  button {
    padding: 10px;
    width: auto;
  }
}

.archived {
  font-size: 0.8rem;
  font-style: italic;
  opacity: 0.7;
}
