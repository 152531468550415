@use 'styles/theme';

.columns,
.column {
  display: grid;
  gap: 2rem;
}

.columns {
  grid-template-columns: 1fr 1fr;
}

.column {
  grid-auto-rows: min-content;
}

.buttons {
  > button + button {
    margin-left: 0.5rem;
  }
}

.container {
  background-color: theme.$color-palest-blue;
  padding: 2rem;
}

.detail,
.checkbox-detail {
  .label {
    font-family: theme.$font-family-secondary;
    font-size: 0.75rem;
    line-height: 0.875rem;
  }

  .value {
    font-size: 0.889rem;
    line-height: 1.438rem;
  }
}

.detail {
  display: flex;
  flex-direction: column;

  .label {
    margin-bottom: 0.25rem;
  }
}

.checkbox-detail {
  align-items: center;
  display: grid;
  gap: 1rem;
  grid-template-columns: 1fr auto;
}

.header {
  font-family: theme.$font-family-secondary;
  font-size: 1rem;
  margin-bottom: 1rem;
}

.descriptionterm,
.descriptiondetail {
  font-family: theme.$font-family-secondary;
  font-size: 0.75rem;
  line-height: 0.875rem;
  padding-bottom: 0.5rem;
}

.descriptionterm {
  font-weight: bold;
  padding-right: 0.5rem;
}

.descriptiondetail {
  margin: 0;
}

.checkbox {
  justify-content: right;
}
