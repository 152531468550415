@use 'styles/theme';

.header {
  align-items: center;
  display: flex;
  height: 3rem;
  justify-content: flex-end;

  h2 {
    margin-bottom: 0;
  }

  &.hasTitle {
    justify-content: space-between;
  }
}

.hidden {
  display: none;
  visibility: hidden;
}

.buttons {
  display: flex;

  .edit {
    background: none;
    border: none;
    color: theme.$color-blue;
    cursor: pointer;
    padding: 0;
  }

  .button {
    font-size: 14px;
    height: 2rem;
    min-width: 120px;

    + .button {
      margin-left: 0.5rem;
    }
  }
}

.archived {
  font-size: 0.8rem;
  font-style: italic;
  opacity: 0.7;
}
