@use 'styles/theme';

.accountContainer {
  cursor: pointer;
  display: grid;
  gap: 1rem;
  grid-template-columns: 2fr 2fr 1fr auto;
  padding: 1.25rem 2rem;
}

.account {
  margin: 15px 0;
  width: 100%;

  .item {
    display: flex;
    flex: 1;
    flex-direction: column;
    min-width: 0;

    strong,
    span {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    strong {
      font-family: theme.$font-family-secondary;
      font-size: 0.75rem;
      margin-bottom: 0.25rem;
    }
  }
}

.viewbuttoncontainer {
  display: flex;
  flex-grow: 0;
  justify-content: right;
}

.viewbutton {
  cursor: pointer;
  position: relative;
  top: 0.5rem;
}

.editable {
  background-color: theme.$color-pale-blue;
  padding: 1rem 2rem 2rem;

  .header {
    margin-bottom: 1rem;

    .buttons {
      margin-left: auto;
    }
  }

  .content {
    padding: 0;
  }
}
