@use 'styles/theme';

h3 {
  font-size: 0.899rem;
  margin-bottom: 2rem;
}
h5 {
  font-size: 0.667rem;
  margin-bottom: 2rem;
}
.list {
  list-style-type: none;
  margin: 0;
  padding-left: 0;
}
.itemContainer {
  display: flex;
  padding: 1.25rem 0;
  position: relative;
}
.item:nth-child(odd) {
  background-color: #eff2f2;
  margin: 0 -3rem;
  padding: 0 3rem;
  width: calc(100% + 6rem);
}
.date {
  display: inline-block;
  font-size: 0.833rem;
  margin-right: 1rem;
  width: 200px;
}
.itemContent {
  display: inline-flex;
  flex-direction: column;
  font-size: 0.778rem;
  gap: 0.25rem;
  margin-right: 1rem;
}
.itemHeader {
  font-weight: bold;
}
.icon {
  position: absolute;
  right: 1rem;
}
p {
  font-size: 0.833rem;
}
.modal {
  &Container {
    padding: 1rem 1.5rem 0 1.5rem;
  }
  .closeButton {
    background-color: transparent;
    color: theme.$color-icon-gray;
    position: absolute;
    right: 1rem;
    top: 1.25rem;
    width: 60px;

    &:active,
    &:hover,
    &:focus {
      background-color: transparent;
    }
  }
  h3 {
    margin-bottom: 2rem;
  }
}
