@use 'styles/theme';

.detail {
  display: flex;
  flex-direction: column;

  .label {
    font-family: theme.$font-family-secondary;
    font-size: 0.75rem;
    line-height: 0.875rem;
    margin-bottom: 0.25rem;
  }

  .value {
    font-size: 0.889rem;
    line-height: 1.438rem;
  }
}
