@use 'styles/theme';

.nav {
  align-content: flex-end;
  align-items: flex-end;
  padding-left: 50px;
  position: sticky;
  top: 50px;
  width: 100%;

  &Link {
    align-items: center;
    border-bottom-left-radius: 20px;
    border-top-left-radius: 20px;
    color: theme.$color-white;
    display: flex;
    font-family: theme.$font-family-secondary;
    font-size: 0.778rem;
    font-weight: 500;
    height: 40px;
    margin: 10px 0;
    padding: 0 22px;
    text-decoration: none;
    text-transform: uppercase;

    &.active {
      background-color: white;
      color: theme.$color-comptia-red;
      padding-top: 2px;
    }
  }
}
