@use 'styles/theme';

.content {
  padding: 2rem;
}
h3 {
  font-size: 0.899rem;
  margin-bottom: 0.75rem;
}
h5 {
  font-size: 0.667rem;
}
.detail {
  display: flex;
  margin-bottom: 0.25rem;
}
.label {
  font-family: theme.$font-family-secondary;
  font-size: 0.667rem;
  font-weight: 500;
  margin-right: 1.5rem;
}
.values {
  display: flex;
  flex-direction: column;
}
.value {
  &Date {
    font-size: 0.833rem;
    margin-bottom: 0.25rem;
  }
  &IP {
    font-size: 0.777rem;
  }
}
.viewAll {
  cursor: pointer;
  display: block;
  font-family: theme.$font-family-secondary;
  font-size: 0.778rem;
  font-weight: bold;
  margin-top: 2rem;
}
