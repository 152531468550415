@use 'styles/theme';

.container {
  display: block;
}

.error {
  color: #c8102e;
  display: block;
  font-size: 0.75rem;
  padding-left: 1rem;
  padding-top: 0.5rem;
}

.innerContainer {
  display: flex;
  font-size: 1.5rem;
}
