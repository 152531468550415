@use 'styles/theme';

.header {
  .breadcrumb {
    .subheading {
      align-items: center;
      display: flex;
      margin-bottom: 1rem;

      .icon {
        color: theme.$color-comptia-red;
        margin-right: 0.5rem;
      }
    }

    .back {
      padding-left: 40px;
      padding-right: 40px;
      width: auto;
    }
  }
}
