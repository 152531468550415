@use 'styles/theme';

.header {
  align-items: center;
  border-bottom: 1px solid theme.$color-light-gray;
  display: flex;
  font-size: 0.8rem;
  height: 70px;
  justify-content: space-between;
  padding-left: 1rem;
  padding-right: 1rem;

  a {
    color: theme.$color-blue;
  }

  .utility {
    align-items: center;
    display: flex;
    list-style: none;

    li + li {
      margin-left: 1rem;
    }
  }
}

.logo {
  img {
    margin-top: 0.5rem;
    max-width: 120px;
  }
}
