@use 'styles/theme';

.content {
  display: grid;
  gap: 2rem;
}

.grid-container.archived {
  background-color: theme.$color-pale-blue;
}

.grid-container {
  background-color: theme.$color-lightest-gray;
  border-top-left-radius: 1rem;
  border-top-right-radius: 1rem;
  display: grid;
  gap: 2rem;
  grid-template-areas: 'leftcol rightcol';
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1.3fr;
}

.leftcol,
.rightcol {
  display: grid;
  gap: 2rem;
}

.leftcol {
  grid-area: leftcol;
}

.rightcol {
  grid-area: rightcol;
  grid-auto-rows: min-content;
  padding-top: 3rem; // To account for the top alignment with the left column.
}
