@use 'styles/theme';

.preview,
.grid {
  display: grid;
  gap: 1rem;
  grid-template-columns: 1fr 1fr 1fr 1fr min-content;

  .column {
    display: grid;
    gap: 1rem;
  }
}

.zendeskId {
  font-family: 'Gotham', sans-serif;
  font-size: 0.75rem;
  line-height: 0.875rem;
  margin-bottom: 0.25rem;

  div {
    font-size: 0.889rem;
    line-height: 1.438rem;
    white-space: pre-line;
  }
}

.grid {
  align-items: flex-start;
}

.preview,
.content {
  padding: 1.25rem 2rem;
}

.content {
  background-color: theme.$color-pale-blue;
}

.buttons {
  display: grid;
  gap: 1rem;
  grid-template-columns: 1fr 1fr;
  margin-left: auto;
  margin-right: 0;
  width: 400px;
}

.toggle,
.edit {
  background: none;
  border: none;
  cursor: pointer;
}
