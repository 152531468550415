.header {
  margin-bottom: 1rem;
}

.grid {
  display: grid;
  gap: 1rem;
}

.buttons {
  display: flex;

  .button {
    width: 190px;
  }
}
